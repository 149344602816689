@import '../../../../assets/scss/constants.scss';

#breadcrumbs{
    padding-top: $padding-breadcrumbs;
    .container{
        display: flex;
        flex-wrap: wrap;
    }
    .item{
        padding: 5px 0px;
        margin: 0 5px;
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        border-radius: 5px;
        position: relative;
        &:not(:first-child){
            &::before{
                content: '/';
                margin-right: 10px;
                color: #fff;
            }
        }
        &.current-item{
            cursor: context-menu;
            color: #a9a9a9;
        }
    }
}