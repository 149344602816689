@import "/src/assets/scss/constants.scss";

#search {
    overflow: auto;
}

.searcher{
    position: relative;
    margin: 0 5px;
    .input{
        input[type=text] {
            border: 0px solid grey;
            float: left;
            width: 85%;
            @media(max-width: $screen-sm){ width: 83%; }
            background: #f1f1f122;
            color: #fff;
            border-radius: 50px;
            padding: 10px 50px;
            text-align: center;
            font-size: 1.2em;
            font-family: monospace;
            &::placeholder { color: #ebebeb; transition: opacity .2s ease; }
            &:focus{
                &::placeholder{ opacity: 0; }
            }
        }
        &.active{
            input[type=text]{
                background: #ffffffba;
                color: #222;
                font-weight: bold;
            }
            .clear-input{
                svg{
                    fill: #222;
                }
            }
        }
        .clear-input{
            position: absolute;
            left: 3%;
            top: 45%;
            transform: translate(0, -50%);
            cursor: pointer;
            svg{ fill: #ebebeb; }
            &:hover{
                svg{ fill: #808080; }
            }
        }
    }
    .button {
        float: left;
        display: flex;
        justify-content: center;
        width: 10%;
        @extend .button;
        margin: 0 2.5%;
        @media(max-width: $screen-sm){
            margin: 1%;
            padding: 7px;
            width: 15%;
        }
        border-radius: 30px;
        border-top-right-radius: 40% !important;
        border-bottom-left-radius: 50% !important;
        border-width: 0 !important; /* Prevent double borders */
        background: $primary;
        color: #fff;
        transition: all .6s ease;
        svg{
            font-size: 1.5em;
            @media(min-width: $screen-sm){ padding: 2px; }
        }
        &:hover {
            background: $primary;
        }
    }
    &::after {
        content: "";
        clear: both;
        display: table;
    }
    .searcher-results{
        position: absolute;
        top: 100%;
        left: 0;
        width: 85%;
        background: #f5f5f5;
        border-radius: 15px;
        z-index: 100;
        max-height: 50vh;
        overflow: auto;
        h4 {
            font-weight: bold;
            text-align: center;
            background: #a1a1a1;
            margin-bottom: 0;
            padding: .5em 0;
        }
        a{
            display: block;
            padding: 10px 20px;
            color: #000;
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;
            &:hover, &.active{
                font-weight: bold;
                background-color: lighten($primary, 50%);
            }
        }
    }
}