@import "/src/assets/scss/constants.scss";

.marker-data-list {
    max-height: 200px;
    overflow-y: scroll;
}

.marker-data{
    border: 1px solid #aaaaaa;
    border-radius: 5px;
    padding: 10px 15px;
    .title{
        h2{
            color: $dark-gray;
            text-align: left;
            font-size: 1.2em;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
    .buttons{
        a{
            @media(max-width: $screen-sm){
                display: block;
                margin-top: 10px;
                text-align: center;
            }
            border: 1px solid $primary-dark;
            padding: 5px;
            color: #fff;
            background-color: $primary-dark;
            border-radius: 5px;
            margin-right: 5px;
            text-decoration: none;
        }
    }
}

.map{
    width: 100%;
    .tc-ctl-popup-menu, .tc-ctl-finfo-attr{ display: none; }
    @media(max-width: $screen-sm){ .container{ height: 100%; } }
}

#mapa {
    width: 100%;
    height: 60vh;
}


#mapas{
    .buttons{
        div {
            text-align: center;
        }
        //display: flex;
        //flex-direction: column;
        .btn-cmn{
            width: 80%;
            margin: 15px auto;
            @media(max-width: $screen-md){
                font-size: .8em;
            }
            &:hover{
                width: 90%;
            }
        }
    }
    .container{
        hr{
            background-color: #fff;
            height: 2px;
            display: block;
            //max-width: 940px;
        }
    }
}