@import "/src/assets/scss/constants.scss";

#themes{
    width: 100%;
    height: 100%;
    overflow: auto;
    .combine-themes{
        overflow: auto;
    }
    .sub-levels{
        max-height: 150px;
        overflow: auto;
    }
}