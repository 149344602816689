@import "/src/assets/scss/constants.scss";

.react-tabs__tab-list {
    background: #fff;
}


#searchadv{
    .buttons{
        div {
            text-align: center;
        }
        //display: flex;
        //flex-direction: column;
        .btn-cmn{
            width: 80%;
            margin: 15px auto;
            @media(max-width: $screen-md){
                font-size: .8em;
            }
            &:hover{
                width: 90%;
            }
        }
    }
    .container{
        hr{
            background-color: #fff;
            height: 2px;
            display: block;
            //max-width: 940px;
        }
    }
}


.dataProject{
    display: block;
    text-align: justify;
    border: 1px solid #222;
    border-radius: 2px;
    margin-bottom: 5px;
    .title{
        display: flex;
        font-size: .9em;
        width: 100%;
        justify-content: center;
        background-color: $primary;
        padding: 5px;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
    }
    .content{
        span{
            font-size: .8em;
            padding: 5px 10px;
            display: inline-flex;
            align-items: center;
            svg{ margin-right: 5px; }
        }
    }
}
