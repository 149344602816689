@import "/src/assets/scss/constants.scss";

#selector{
    position: sticky;
    top: 0;
    z-index: 101;
    display: flex;
    span{
        background-color: $primary;
        color: #fff;
        padding: 5px 15px;
        text-transform: uppercase;
        font-weight: bold;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0 5px;
        justify-content: space-between;
        background-color: #fff;
        li{
            list-style: none;
            padding: 5px 10px;
            font-weight: bold;
            color: $primary;
            cursor: pointer;
        }
    }
}