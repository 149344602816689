@import '/src/assets/scss/constants.scss';

.btn-moreinfo{
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 1000;
    @media(max-width: $screen-md){
        top: 5px;
        right: 5px;
    }
    .interviewHome & {
        right: 60px
    }
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    .btn-saber{
        color: #fff;
        text-decoration: none;
        font-size: 1.2em;
        font-weight: 600;
        padding: 10px;
        text-transform: uppercase;
        &:hover{ svg{ background: $primary-dark; } }
        svg{
            padding: 5px;
            background: $dark-gray;
            border-radius: 20px;
            height: 25px;
            width: 30px;
            border-top-right-radius: 38% !important;
            border-bottom-left-radius: 45% !important;
            transition: background .3s ease;
        }
    }
}
.site-footer{
    justify-content: space-between;
    margin: 15px;
    padding: 20px 15px;
    display: flex;
    @media(max-width: $screen-md){ flex-direction: column; }
    svg{ width: 100%; }
    a{
        &:nth-child(2){
            img{ max-height: 50px; }
        }
        img{
            padding: 0 15px;
            max-height: 60px;
            @media(max-width: $screen-md){
                max-height: 60px;
                max-width: 300px;
                display: block;
                margin: 15px auto;
            }
            max-width: 100%;
        }
    }
}