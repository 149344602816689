@import "/src/assets/scss/constants.scss";

#learn-more{
    @media(min-width: $screen-sm){ padding-top: $header-height; }
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    @media(max-width: $screen-sm){ max-width: 90%; margin: 0 auto; }
    &::after{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        @media(min-width: $screen-sm){
            opacity: 0.9;
            max-width: 90%;
            max-height: 90%;
            filter: brightness(0.4);
            margin-top: 2.5%;
            margin-left: 5%;
            border-radius: 5px;
        }
    }
    .text{
        @media(min-width: $screen-sm){ font-size: 1.2em; }
        color: #000;
        .mark{
            color: $primary;
            padding: 0;
            font-weight: bold;
        }
    }
    .mark{
        text-decoration: none;
    }
}