@import '../../../../assets/scss/constants.scss';

mark{ background-color: #fffc66 !important; }

.card{
    margin: 15px auto;
    .search-item{
        width: 100%;
        padding: 15px;
        .text-item{
            display: inherit;
            text-align: justify;
            padding: 0 15px;
        }
        .img-item{
            position: relative;
            img, video{
                width: 100%;
                border-radius: 5px;
            }
            .play-icon{
                position: absolute;
                right: 10%;
                top: 50%;
                transform: translate(0, -50%);
                color: #ffffffaa;
                font-size: 5em;
            }
        }
        .info-item{
            .name{
                display: block;
                font-size: 1.3em;
                font-weight: bold;
                margin: 5px auto;
            }
            .icon{
                text-align: right;
                font-size: 1.3em;
                color: #e74c3c;
            }
            .city-info{
                align-self: center;
                .city-label{
                    text-align: center;
                    font-weight: bold;
                }
                .city-value{
                    margin: 0 5px;
                }
            }
        }
    }
}