@import "/src/assets/scss/constants.scss";


.header{
    @media(max-width: $screen-sm){
        background-color: #2b1d16;
        z-index: 1;
    }
    width: 100%;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    padding: 10px 15px;
    .header-logo{
        .logo{
            max-width: 70%;
            max-height: 100%;
            transition: all .4s ease;
            cursor: pointer;
            &.min{ max-width: 50%; }
        }
    }
    .header-text{
        color: $dark-gray;
        font-size: 1.2em;
        @media(max-width: $screen-sm){ font-size: .7em; }
        text-align: right;
        flex: auto;
        align-self: center;
        span{
            font-style: italic;
        }
    }
    .header-breadcrumbs{
        align-self: center;
        @media(max-width: $screen-sm){ display: none; }
    }
    .current-site{
        color: $primary-orange;
        @media(min-width: 1226px){ font-size: 1.25em; }
        @media(max-width: $screen-sm){ font-size: .8em; }
        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        margin-left: 30px;
        &::before{
            content: '';
            background-color: $primary-orange;
            position: absolute;
            left: -15px;
            top: 0;
            width: 2px;
            height: 100%;
        }
    }
}