@import "/src/assets/scss/constants.scss";

.ReactModalPortal{
    [aria-label="videoModal"]{
        position: absolute !important;
        inset: auto !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        @media(max-width: $screen-sm){ width: 100%; }
        video{
            max-height: 100%;
            width: 80%;
        }
    }
}