@import "/src/assets/scss/constants.scss";

#home{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    .centered-home{
        height: max-content;
        width: 95%;
        max-width: 600px;
        .searcher-text{
            color: #fff;
            margin-bottom: 5px;
            font-style: italic;
            display: block;
            width: 85%;
            text-align: center;
            font-size: .9em;
            @media(max-width: $screen-sm){ width: 100%; }
        }
        .help-text {
            color: white;
        }

        .btn-advanzed{
            a {
                color: #fff;
                font-size: 1em;
                font-weight: bold;
                text-decoration: none;
                text-transform: uppercase;
            }
            margin-top: 5px;

            display: block;
            width: 85%;
            text-align: right;

            @media(max-width: $screen-sm){ width: 100%; }
        }
        padding: 20px 10px;
        // box-shadow: 1px 1px 1px #bbbbbbaa;
    }
    .buttons-middle{
        width: 100%;
        margin: 30px auto;
        .buttons-home{
            display: flex;
            max-width: 1000px;
            justify-content: center;
            margin: 15px auto;
            @media(max-width: $screen-sm){
                max-width: 100%;
                flex-direction: column;
                margin: 15px auto;
            }
        }
    }
    .container{
        hr{
            margin: 30px auto;
            background-color: #fff;
            height: 2px;
            display: block;
            max-width: 940px;
        }
    }
}
.ReactModalPortal{
    >div{
        background-color: #ffffffd9 !important;
        [aria-label="learnMoreModal"]{
            border-width: 0 !important;
            @media(max-width: $screen-sm){
                inset: 10px !important;
            }
            h2{ color: #000; }
            .mark{ background-color: transparent !important; }
            .closeModal{
                position: fixed;
                top: 7vh;
                right: 7vw;
                cursor: pointer;
                color: #fff;
                font-size: 2em;
                @media(max-width: $screen-sm){
                    top: 3vh;
                    right: 5vw;
                }
                svg{
                    background: $primary;
                    border-radius: 50%;
                    border: 1px solid #000;
                    padding: 5px;
                    transition: background .4s ease;
                }
                &:hover{
                    svg{
                        background-color: $primary-dark;
                    }
                }
            }
        }
    }
}