@import "/src/assets/scss/constants.scss";

html{ overflow: hidden; }

body {
    font-family: "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

h2{
    margin: 10px auto;
    text-align: center;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

.button{
    padding: 10px;
    font-size: 17px;
    border: 1px solid grey;
    cursor: pointer;
}

button.arrows {
    background: none;
    border: none;
}

.main_content{
    perspective: 1000px;
    overflow: hidden;
    &.menu-open{
        .app-content{
            transform: rotateY(10deg) !important;
            @media(max-width: $screen-sm){
                transform: scale(.85) translate(-55%) !important;
                filter: blur(1px);
            }
            overflow: hidden;
            pointer-events: none;
        }
        #menu{
            .bg-image{
                filter: brightness(.05);
                transform: scale(1.1);
            }
            ul{
                right: 0;
                li{ opacity: 1; }
            }
        }
    }
    .app-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        transform: rotateY(0deg);
        transform-style: preserve-3d;
        transform-origin: left;
        transition: .4s ease-in;
        @media(max-width: $screen-sm){
            overflow: auto;
        }
        > div > .container{
            @media(max-width: $screen-sm){
                max-height: 70vh;
                overflow: auto;
            }
        }
    }
}

.btn-cmn{
    position: relative;
    color: #fff !important;
    margin: 0 10px;
    font-weight: bold;
    transition: all .6s ease;
    border-radius: 10px !important;
    overflow: hidden;
    &:hover{
        width: 100%;
        color: #fff !important;
        background-color: $dark-gray;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }
}


.buttons{
    display: flex;
    margin-top: 15px;
    .btn-interview{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        transition: all.4s ease;
        border: 1px solid #000;
        background-color: $primary;
        color: #fff;
        margin-right: 15px;
        &.isProject{ margin-right: 0px; }
        svg{ margin-left: 5px; }
        &.active{
            background-color: #aaa;
        }
    }
}

.theme-item{
    display: flex;
    width: 32%;
    margin: 10px .5%;
    color: #fff;
    &:not(.project-item){ background-color: #a1a1a1; }
    &.full{
        width: 100%;
        background-color: #d1d1d1;
    }
    font-weight: 700;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    border: 0;
    outline: 0;
    font-size: 1.1rem;
    transform: skew(-21deg);
    border-radius: 10px;
    .btn-combine{
        width: 100%;
        justify-content: center;
        background-color: transparent;
        transform: skew(21deg);
    }
    @media(max-width: $screen-sm){ width: 90%; margin: 10px auto; }
    &:hover{
        .line-skew{
            width: 20%;
        }
    }
    .line-skew{
        transition: all .4s ease;
        position: absolute;
        left: 0;
        width: 10%;
        height: 100%;
        top: 0;
    }
    &:visited { color: #fff; }
    & > a { display: inline-block; transform: skew(21deg); } /* Un-skew the text */
    a{
        padding: 12px 0;
        width: 100%;
        justify-content: center;
        color: #000;
        font-weight: bold;
        text-align: center;
        align-self: center;
        text-decoration: none;
    }
}

.btn.btn-base {
    background: $primary;
    color: #fff;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 0;
    outline: 0;
    font-size: 1.1rem;
    display: inline-block;
}


.btn-cmn{
    // background-color: transparent;
    position: relative;
    padding: 30px 0;
    background: $primary;
    &::before{
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 10px;
        z-index: -2;
    }
    &::after{
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 15px;
        transition: all .4s ease;
        filter: grayscale(1) brightness(.55);
    }
    &:hover{
        &::after{
            filter: grayscale(.7) brightness(.6);
            bottom: 0;
            left: 0;
        }
        background: $primary-orange;
    }
    &.projects{
        &::before{ background: $primary-orange; }
    }
    &.btn {
        color: #fff;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        border: 0;
        outline: 0;
        font-size: 1.1rem;
        display: inline-block;
        transform: skew(-21deg);
        transition: all .6s ease;
        width: 32%;
        @media(max-width: $screen-sm){
            width: 90%;
            margin: 10px auto;
            padding: 15px 0;
        }
        &:visited { color: #fff; }
        &:hover {
            @media(min-width: $screen-sm){ width: 60%; }
        }
        & > span { display: inline-block; transform: skew(21deg); } /* Un-skew the text */
        &.btn-big {
            width: 64%;
        }

    }
    &.filter1 {
        color: #fff;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        border: 0;
        outline: 0;
        font-size: 16px;
        display: inline-block;
        transform: skew(-21deg);
        transition: all .6s ease;
        padding: 15px 0;
        width: 15%;
        background-color: rgba(255, 255, 255, 0.25);
        border: 2px solid rgba(255, 255, 255, 0.25);
        @media(max-width: $screen-sm){
            width: 90%;
            margin: 10px auto;
            padding: 15px 0;
        }
        &:visited { color: #fff; }
        &:hover {
            @media(min-width: $screen-sm){ width: 30%; }
        }
        & > span { display: inline-block; transform: skew(21deg); } /* Un-skew the text */
    }
    &.filter2 {
        color: #fff;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        border: 0;
        outline: 0;
        font-size: 16px;
        display: inline-block;
        transform: skew(-21deg);
        transition: all .6s ease;
        padding: 15px 0;
        width: 20%;
        background-color: rgba(255, 255, 255, 0.25);
        border: 2px solid rgba(255, 255, 255, 0.25);
        @media(max-width: $screen-sm){
            width: 90%;
            margin: 10px auto;
            padding: 15px 0;
        }
        &:visited { color: #fff; }
        &:hover {
            @media(min-width: $screen-sm){ width: 30%; }
        }
        & > span { display: inline-block; transform: skew(21deg); } /* Un-skew the text */
    }
    &.active {
        border: 2px solid #fff;
        background-color: $primary;
    }
}
h3.title-filter {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    padding: 30px 0 20px 5px;
}
.back {
    color: #000;
    text-decoration: none;
    display: block;
    margin-bottom: 30px;
    position: relative;
    padding-left: 20px;
    &:before {
        content: '';
        background-image: url(../../assets/images/ico-back.png);
        display: block;
        background-repeat: no-repeat;
        width: 14px;
        height: 12px;
        position: absolute;
        left: 0;
        top: 5px;

    }
    &:hover {
        color: $primary;
    }
}
.bg-image{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    filter: brightness(.3);
    background-image: url('../../assets/images/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .4;
        background-color: $primary-orange;
    }
}

#menu-btn{
    margin-right: 15px;
    font-size: 1.5em;
    z-index: 100;
    color: #fff;
    cursor: pointer;
    position: fixed;
    top: 30px;
    right: 15px;
    @media(max-width: $screen-md){ top: 10px; }
}

#menu{
    z-index: -2;
    .bg-image{
        transition: all .4s ease;
        filter: brightness(.3);
        background-image: url('../../assets/images/bg.jpg');
        background-size: cover;
    }
    .closeMenu{
        position: absolute;
        top: calc(#{$header-height}/2);
        font-size: 1.3em;
        right: 30px;
        color: #fff;
    }
    ul{
        transition: right .6s ease;
        width: 260px;
        @media(max-width: $screen-sm){
            max-width: 230px;
        }
        height: 100vh;
        position: absolute;
        right: -260px;
        top: $header-height;
        li{
            list-style-type: none;
            padding: 10px 5px;
            opacity: 0;
            transition: opacity .2s ease;
            position: relative;
            &::after{
                content: '';
                width: 0px;
                height: 3px;
                background-color: $primary;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: all .6s ease;
            }
            &:hover{
                &::after{
                    width: 80%;
                }
                a{
                    margin-left: 0px;
                }
            }
            a{
                display: block;
                width: 80%;
                text-decoration: none;
                text-transform: uppercase;
                color: #fff;
                font-size: 1.3em;
                margin: 0 15px;
                transition: all .2s ease;
            }
        }
    }
}

.loader1, .loader2 {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loader1 div, .loader2 div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: loader1 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .loader2 div {
    border: 4px solid #a3233a;
  }
  .loader1 div:nth-child(2), .loader2 div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes loader1 {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  @keyframes loader2 {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }



/* nous estils */
.content {
    overflow: auto;
    .description{
        color: #fff;
        padding: 15px;
        background-color: transparentize($primary, 0.4);
        font-size: 1.1em;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        @media(max-width: $screen-sm){ font-size: .9em; }
    }
    .result-list {
        background: #fff;
        padding: 2em 3em;
        margin-top: 1.5em;
        h3, h4 {
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
        }
        h4 {
            text-transform: none;
            margin-bottom: 2em;
            color: $primary;
        }
        .docs-list {
            a {
                border: 1px solid $light-gray;
                box-shadow: 0 4px 6px -6px #222;
                margin-bottom: .5em;
                &:hover {
                    outline: $primary solid;
                }
            }
        }
        .victims-list {
            div {
                padding-bottom:  .2em;
                padding-top:  .2em;
            }
            a {
                border-left: 1px solid $base;
                padding-left: .5em;
                color: $base;
                text-decoration: none;
                &:hover {
                    font-weight: bold;
                    color: $primary;
                    border-left: 2px solid $primary;
                    margin-left: .5em;
                }
                /*&::before {
                    content: '|';
                    margin-right: .5em;
                }*/
            }
        }
    }

    .victim-card {
        background: #fff;
        padding: 2em 3em;
        margin-top: 1.5em;
        h3 {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 1.5em;
            text-transform: uppercase;
            svg {
                display: block;
                margin-bottom: .5em;
            }
        }
        h4 {
            font-weight: 700;
        }
        ul.victim-info {
            padding: 0;
            list-style: none;
            li {
                span {
                    font-weight: 300;
                    text-transform: uppercase;
                    border-right: 1px solid $base;
                    padding-right: .5em;
                    margin-right: .5em;
                }
                font-weight: 700;
                padding: .2em 0;
            }
        }
        ul.victim-ref {
            padding: 0;
            list-style: none;
            li {
                padding: .2em 0;
            }
            button, a {
                padding: .2em 0;
                border: none;
                background-color: transparent;
                color: $primary;
                font-weight: 700;
                text-decoration: none;
                svg {
                    display: inline-block;
                    margin-right: 1em;
                }
            }
        }
        a {
            color: #a3233a;
            text-decoration: none;
        }
    }

    .victim-card-map {
        background: #fff;
        margin-top: 1.5em;

        .map-wrapper {
        }
        .victim-card {
            margin-top: 0;
        }
    }

    .fosa-card {
        background: #2c2420;
        color: #fff;
        padding: 2em 3em;
        margin-top: 1.5em;
        h2 {
            text-align: left;
            margin-bottom: 15px;
        }
        h3 {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 20px;
            text-transform: uppercase;
            background-color: rgba(216, 216, 216, 0.2);
            border-radius: 26px;
            padding: 10px 15px;
            display: inline-block;
            margin-top: 50px;
            svg {
                display: block;
                margin-bottom: .5em;
            }
        }
        h4 {
            font-weight: 400;
            font-size: 16px;
            text-transform: uppercase;
        }
        p.loc {
            position: relative;
            padding-left: 35px;
            &:before {
                content: '';
                background-image: url('../../assets/images/ico-ubicacio.png');
                background-repeat: no-repeat;
                width: 25px;
                height: 25px;
                display: block;
                position: absolute;
                left:0;
                top: -2px;
            }
        }
        p.cal {
            position: relative;
            padding-left: 35px;
            &:before {
                content: '';
                background-image: url('../../assets/images/ico-data.png');
                background-repeat: no-repeat;
                width: 25px;
                height: 25px;
                display: block;
                position: absolute;
                left:0;
                top: -2px;
            }
        }
        .btn-map {
            background-color: $primary;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            border-radius: 30px;
            text-transform: uppercase;
            text-decoration: none;
            display: inline-block;
            margin-top: 30px;
            padding: 40px;
            @media(min-width: $screen-sm){
                background-image: url('../../assets/images/back-map.png');
                background-repeat: no-repeat;
                padding: 54px;
                font-size: 24px;
            }

        }
        .btn-mas {
            color: #fff;
            background-color: $primary;
            font-size: 14px;
            font-weight: 700;
            border-radius: 26px;
            padding: 10px 20px;
            text-decoration: none;
        }
        ul.victim-info {
            padding: 0;
            list-style: none;
            li {
                span {
                    font-weight: 300;
                    text-transform: uppercase;
                    border-right: 1px solid $base;
                    padding-right: .5em;
                    margin-right: .5em;
                }
                font-weight: 700;
                padding: .2em 0;
            }
        }
        ul.victim-list {
            list-style: none;
            padding-left: 0;
            li {
                position: relative;
                &:before {
                    content:'|';
                    position: absolute;
                }
                a {
                    color: #fff;
                    text-decoration: none;
                    padding-left: 15px;
                    font-weight: 300;
                    span {
                        font-weight: 700;
                    }
                }
            }
        }
        ul.victim-ref {
            padding: 0;
            list-style: none;
            li {
                padding: .2em 0;
            }
            button, a {
                padding: .2em 0;
                border: none;
                background-color: transparent;
                color: $primary;
                font-weight: 700;
                text-decoration: none;
                svg {
                    display: inline-block;
                    margin-right: 1em;
                }
            }
        }
        .slick-slide img {
            width: 100%;
        }
        .slick-dots {
            bottom: -35px;
        }
        .slick-dots li.slick-active button:before {
            color: #fff;
        }
        .slick-dots li button:before {
            color: #fff;
            font-size: 15px;
        }
    }
}
 .transcripcion {
    display: block;
    margin-top: 1em;
 }


.leaflet-container a.leaflet-popup-close-button {
    color: $primary!important;
    padding: 1em 4px 0 0;
}


.leaflet-popup-content, .map-popup-content {
    font-size: 12px;
    margin: 1em 2em!important;
    h6 {
        color: $primary;
        font-weight: 700;
        font-size: 1rem!important;
        margin: 0 0 .5rem 0!important;
    }
    ul {
        font-size: 1.1em;
        padding-right: 0!important;
        padding-left: 0!important;
        list-style: none;
        margin: 0;
        li {
            padding-bottom:  .2em;
            padding-top:  .2em;
            a {
                border-left: 1px solid $base;
                padding-left: 1em;
                color: $base;
                text-decoration: none;
                &:hover {
                    font-weight: bold;
                    color: $primary;
                    border-left: 2px solid $primary;
                    margin-left: .5em;
                }
            }
        }
    }
}


.searcheradv{
    .dbfilter {
        font-size: .8em;
        margin-top: 2em;
        padding: 0;
        li {
            padding: .5em 1em .5em 0;
            width: 22%;
            @media(max-width: $screen-sm){ width: 100%; }
            @media(max-width: $screen-md){ width: 33%; }
            display: inline-block;
            &.long {
                width: 26%;
                @media(max-width: $screen-sm){ width: 100%; }
                @media(max-width: $screen-md){ width: 33%; }
            }
        }

    }
    .input{
        input[type=text], select {
            border: 0px solid grey;
            float: left;
            width: 100%;
            //@media(max-width: $screen-sm){ width: 83%; }
            background: #f1f1f122;
            color: #fff;
            border-radius: 50px;
            padding: 10px 50px;
            //text-align: center;
            text-align: left;
            font-size: 1.2em;
            font-family: monospace;
            &::placeholder { color: #ebebeb; transition: opacity .2s ease; }
            &:focus{
                &::placeholder{ opacity: 0; }
            }
            margin: 1em 0;
        }
        select {
            appearance: none;
            text-align: left;
        }
        select option {
            color: #000;
        }




        label {
            color: #fff;
            text-align: left;
            width: 100%;
            font-size: 1.2em;
            font-family: monospace;
            display: grid;
            grid-template-columns: 1em auto;
            gap: 0.5em;
            input[type="radio"] {
                margin-right: .5em;
                background-color: #fff;
                accent-color: $primary;
                color: $primary;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                transform: translateY(.2em);
            }
        }
    }
    .button {
        float: left;
        display: flex;
        justify-content: center;
        //width: 10%;
        @extend .button;
        //margin: 0 2.5%;
        margin: 1em;
        @media(max-width: $screen-sm){
            //margin: 1%;
            //padding: 7px;
            //width: 15%;
        }
        border-radius: 30px;
        border-top-right-radius: 40% !important;
        border-bottom-left-radius: 50% !important;
        border-width: 0 !important; /* Prevent double borders */
        background: $primary;
        color: #fff;
        transition: all .6s ease;
        svg{
            font-size: 1.5em;
            @media(min-width: $screen-sm){ padding: 2px; }
        }
        &:hover {
            background: $primary;
        }
    }
    &::after {
        content: "";
        clear: both;
        display: table;
    }
}




.react-tabs__tab-list {
    border: 0;
    margin-left: -1.5em;
    .react-tabs__tab {
        border: 1px solid $primary;
        border-radius: .5em;
        color: $primary;
        font-weight: 700;
        padding: 1em 1.5em;
        margin: 0 1em;
        transform: skew(-21deg);
        span {
            display: inline-block;
            transform: skew(21deg);
        }
        a {
            display: inline-block;
            transform: skew(21deg);
            color: $primary;
            text-decoration: none;
        }

        &.react-tabs__tab--selected {
            color: #fff;
            background-color: $primary;
        }

    }
}
.react-tabs__tab-panel {
    h3 {
        margin-top: 2em;;
    }
}



.fosa-tabs .react-tabs__tab-list {
    border: 0;
    margin-left: -1.5em;
    background: transparent;
    .react-tabs__tab {
        border: 1px solid $primary;
        border-radius: .5em;
        color: $primary;
        font-weight: 700;
        padding: .5em 1em;
        margin: 0 .5em;
        transform: skew(-21deg);
        span {
            display: inline-block;
            transform: skew(21deg);
        }
        a {
            display: inline-block;
            transform: skew(21deg);
            color: $primary;
            text-decoration: none;
        }

        &.react-tabs__tab--selected {
            color: #fff;
            background-color: $primary;
        }

    }
}

.fosa-tabs .react-tabs__tab:focus:after {
    content: none;
}

.fosa-tabs .react-tabs__tab-panel {
    h3 {
        margin-top: 2em;;
    }
}


.trecorrido {
    th, td {
        text-align: center;
        padding: 10px;
        font-weight: bold;
        .red-num {
            color: #fff;
            background-color: $primary;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
        .loc-head {
            padding: 0 0 0 15px;
        }
        .loc {
            position: relative;
            display: inline-block;
            width: 100%;
            //padding: 0 30px;
            padding: 0 0 0 15px;
            &:before/*,
              &:after */{
                content: "";
                display: block;
                width: 10px;
                height: 2px;
                background-color: $primary;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }

              &:before {
                left: 0;
              }

              &:after {
                right: 0;
              }
        }
        .doc {
            position: relative;
            display: inline-block;
            padding: 0 30px;
            &:before {
                content: "";
                display: block;
                width: 15px;
                height: 2px;
                background-color: $primary;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
              }
        }
    }
}
.btn-map-square {
    background-color: $primary;
    color: #fff !important;
    font-weight: 700;
    border-radius: 30px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    margin-top: 30px;
    text-align: center;
    color: #fff;
    background-image: url('../../assets/images/ver-recorrido-en-mapa.png');
    background-repeat: no-repeat;
    padding: 25px 20px;
    font-size: 18px;
    width: 135px;
    height: 135px;


}
audio {
    width: 100%;
}
video {
    width: 100%;
}