$primary: #a3233a;
$base: var(--bs-body-color);
$primary-dark: darken($primary, 10%);
$light-gray: #e8eaeb;
$dark-gray: #56585a;
$primary-orange: #bc622e;

$filter-color1: #6735a0;
$filter-color2: #924d4d;
$filter-color3: #3595a0;

$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$header-height: 80px;
$padding-breadcrumbs: $header-height/4;

.hidden{
    display: none !important;
}

.onomastic{
    color: #fff;
    font-weight: 700;
    background-color: $filter-color1;
    border: 1px solid darken($filter-color1, 20%);
}

.thematic{
    color: #fff;
    font-weight: 700;
    background-color: $filter-color3;
    border: 1px solid darken($filter-color3, 20%);
}

.absolute-center{
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}