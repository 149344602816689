@import '/src/assets/scss/constants.scss';

#expositions{
    .buttons{
        display: flex;
        flex-direction: column;
        .btn-cmn{
            width: 80%;
            margin: 15px auto;
            @media(max-width: $screen-md){
                font-size: .8em;
            }
            &:hover{
                width: 90%;
            }
        }
    }
}