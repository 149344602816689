@import "/src/assets/scss/constants.scss";

#projects{
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    h2{
        color: #fff;
    }
    #project{
        @media(max-width: $screen-sm){ overflow: auto; }
        .description{
            color: #fff;
        }
    }
    .description{
        padding: 15px;
        background-color: transparentize($primary, 0.4);
        font-size: 1.1em;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        @media(max-width: $screen-sm){ font-size: .9em; }
    }
}

.ReactModalPortal{
    [aria-label="videoModal"]{
        position: absolute !important;
        inset: auto !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        @media(max-width: $screen-sm){ width: 100%; }
        video{
            max-height: 100%;
            width: 80%;
        }
    }
}