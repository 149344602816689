@import "/src/assets/scss/constants.scss";

#menu-languages{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 15px;
    padding-right: 15px;
    .language{
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        padding: 5px 15px;
        cursor: pointer;
        text-transform: uppercase;
        &.active{
            pointer-events: none;
            color: #a9a9a9;
            cursor: context-menu;
            border-bottom: 3px solid $primary-dark;
        }
    }
}

.footer-home{
    @media(max-width: $screen-sm){
        background-color: $primary-orange;
    }
    #menu-languages{
        padding-right: 35px;
        @media(max-width: $screen-sm){
            padding: 0;
            justify-content: center;
        }
    }
}