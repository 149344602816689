@import '/src/assets/scss/constants.scss';

#victims{
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
}




.victims {
    ul {
        list-style: none;
        padding: .5em;
        li {
            break-inside: avoid;
        }
        &.doble {
            columns: 2;
        }
    }
    background-color: #ffffff3d;
    border-end-start-radius: 5px;
    border-end-end-radius: 5px;
    overflow: auto;
    .not-found-text{
        color: #fff;
    }
    .informant-name{
        display: flex;
        margin: 15px 0;
        justify-content: space-around;
    }
    h3.title{
        text-align: center;
        font-size: 1.2em;
        margin: 5px auto;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
    }
    @media(min-width: $screen-sm){
        height: 100%;
        &.third-term{ height: 75vh; }
        .interviews-cards{
            overflow: auto;
            height: 100%;
            padding: 0 15px;
        }
    }
}

.card{
    overflow: hidden;
    .interview-item, .search-item{
        border-radius: 5px;
        margin: 15px 0;
        .period{
            display: block;
            .title{ font-weight: bold; }
            .text{ font-style: italic; }
        }
        h3{
            margin-top: 10px;
            font-size: 1.5em;
        }
        .video-thumbnail{
            position: relative;
            cursor: pointer;
            &:hover{
                svg{
                    fill: #fff;
                }
            }
            img{
                width: 100%;
                @media(min-width: $screen-sm){ max-width: 295px; }
                height: 100%;
            }
            .react-video-thumbnail-image{
                img, canvas{
                    width: 100%;
                    @media(min-width: $screen-sm){ max-width: 295px; }
                    height: 100%;
                }
                video{ display: none; }
            }
            &.loading-bg{
                .react-video-thumbnail-image{
                    &::before{
                        content: '';
                        width: 100%;
                        height: 100%;
                        background-color: #000;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 0;
                    }
                }
            }
            svg{
                fill: $primary;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all .4s ease;
                font-size: 4em;
                @media(max-width: $screen-sm){ font-size: 5em; }
            }
        }
        .dataProject{
            display: block;
            text-align: justify;
            border: 1px solid #222;
            border-radius: 2px;
            margin-bottom: 5px;
            .title{
                display: flex;
                font-size: .9em;
                width: 100%;
                justify-content: center;
                background-color: $primary;
                padding: 5px;
                color: #fff;
                font-weight: bold;
                text-transform: uppercase;
            }
            .content{
                span{
                    font-size: .8em;
                    padding: 5px 10px;
                    display: inline-flex;
                    align-items: center;
                    svg{ margin-right: 5px; }
                }
            }
        }
    }
    .summary-box{
        height: 0px;
        transition: height .2s ease;
        text-align: justify;
        &.show{
            padding: 15px;
            height: auto;
            overflow: auto;
        }
    }

    .reel-container{
        text-align: justify;
        .reel-item{
            font-size: .7em;
            padding: 5px 10px;
            display: inline-block;
        }
    }
}

.victim-ref-list {
    margin-right: 2em;
    img {
        width: 100%;
    }
    >div {
        margin-bottom: 1em;
        border-bottom: 1px solid $light-gray;
        padding-bottom: 1em;
    }
    video {
        width: 100%;
    }

}




.react-responsive-modal-modal {
    min-width: 50vw;
}
.react-responsive-modal-closeButton {
    top: 0;
    right: 0;
}

.modal-popup {
    font-size: 12px;
}
.modal-popup > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}
.modal-popup > .content {
    width: 100%;
    padding: 1em 0;
    max-height: 80vh;
    overflow-x: hidden;
}
.modal-popup > .actions {
    margin: auto;
}
.modal-popup > .actions {
    width: 100%;
    padding: 10px 5px;
    text-align: center;
}
.modal-popup > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}
