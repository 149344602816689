@import "/src/assets/scss/constants.scss";

#filter-items{
    margin-bottom: 15px;
    .filter-item{
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 700;
        margin-right: 10px;
        span.label{ cursor: default; }
        .remove{
            cursor: pointer;
            margin-left: 10px;
            transition: all .3s ease;
            &:hover{ fill: $primary-dark; }
        }
    }
}

.combine-themes{
    .combine-container{
        .combine-item{
            button{
                width: 100%;
                margin: 5px auto;
            }
        }
    }
}