@import '/src/assets/scss/constants.scss';

.modal-video{
    display: flex;
    img {
        max-width: 100%;
    }
    .modal-close{
        padding: 0 15px;
        cursor: pointer;
        svg{
            fill: $primary;
            font-size: 1.5em;
        }
    }
}