@import '/src/assets/scss/constants.scss';

.docs-card {
    padding-bottom: .5em;
    text-align: center;
    .docs-body {
        height: 100%;
        background-color: #fff;
    }
    .img {
        padding: .5em;
        display: block;
    }
    img {
        max-width: 100%;
        max-height: 300px;
    }
    p {
        padding: 0 .5em;
        text-align: left;
    }
}


.card{
    overflow: hidden;
    .interview-item, .search-item{
        border-radius: 5px;
        margin: 15px 0;
        .period{
            display: block;
            .title{ font-weight: bold; }
            .text{ font-style: italic; }
        }
        h3{
            margin-top: 10px;
            font-size: 1.5em;
        }
        .video-thumbnail{
            position: relative;
            cursor: pointer;
            &:hover{
                svg{
                    fill: #fff;
                }
            }
            img{
                width: 100%;
                @media(min-width: $screen-sm){ max-width: 295px; }
                height: 100%;
            }
            .react-video-thumbnail-image{
                img, canvas{
                    width: 100%;
                    @media(min-width: $screen-sm){ max-width: 295px; }
                    height: 100%;
                }
                video{ display: none; }
            }
            &.loading-bg{
                .react-video-thumbnail-image{
                    &::before{
                        content: '';
                        width: 100%;
                        height: 100%;
                        background-color: #000;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 0;
                    }
                }
            }
            svg{
                fill: $primary;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all .4s ease;
                font-size: 4em;
                @media(max-width: $screen-sm){ font-size: 5em; }
            }
        }
        .dataProject{
            display: block;
            text-align: justify;
            border: 1px solid #222;
            border-radius: 2px;
            margin-bottom: 5px;
            .title{
                display: flex;
                font-size: .9em;
                width: 100%;
                justify-content: center;
                background-color: $primary;
                padding: 5px;
                color: #fff;
                font-weight: bold;
                text-transform: uppercase;
            }
            .content{
                span{
                    font-size: .8em;
                    padding: 5px 10px;
                    display: inline-flex;
                    align-items: center;
                    svg{ margin-right: 5px; }
                }
            }
        }
    }
    .summary-box{
        height: 0px;
        transition: height .2s ease;
        text-align: justify;
        &.show{
            padding: 15px;
            height: auto;
            overflow: auto;
        }
    }

    .reel-container{
        text-align: justify;
        .reel-item{
            font-size: .7em;
            padding: 5px 10px;
            display: inline-block;
        }
    }
}
